import { AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';

export class CustomValidators {

  static onlyChar(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value) {
        const re = new RegExp('^[a-zA-Z ]*$');
        if (!re.test(control.value)) {
          return { onlyChar: true };
        }
      }
      return null;
    };
  }

  static mustMatch(controlName: string, matchingControlName: string) {
    const matchingError = { mustMatch: true };

    return (formGroup: FormGroup) => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);

      if (control?.value && matchingControl?.value) {
        if (control.value !== matchingControl.value) {
          matchingControl.setErrors(matchingError);
          return matchingError;
        } else {
          matchingControl.setErrors(null);
        }
      }

      return null;
    };
  }
}
